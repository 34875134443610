// external imports
import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

// helpers
import { up, addResponsivity } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
  createAnchor,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import SEO from "../components/SEO"
import T from "../lib/intl/T"
import IntlContext from "../lib/intl/IntlContext"
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  VH,
  Listing,
  WaveImage,
  AboutSection,
  ContactFormSection,
  GalleryPopup,
  RoundedImage,
  Button,
} from "../components"
import { PROCEDURES_INFOS } from "../lib/config"
import ProcedureItem from "../components/ProcedureItem"

import procedureIcon1 from "../data/images/about/money-icon.svg"
import procedureIcon2 from "../data/images/about/purse-icon.svg"
import procedureIcon3 from "../data/images/about/doctor-icon.svg"

import plasticIcon from "../data/images/about/hexa-plastic-icon.svg"
import orthopedicsIcon from "../data/images/about/hexa-orthopedics-icon.svg"

const proceduresInfoIcons = [procedureIcon1, procedureIcon2, procedureIcon3]

// assets
// import heroImage from '../data/images/logo.png'

// custom styled components

const topBp = 1050
const TopImageWrapper = styled.div`
  position: relative;
  top: -40px;
  left: 0;
  max-width: 416px;
  width: 100%;
  ${up(topBp)} {
    position: absolute;
  }
`
const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
`
const ProcedureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${up("mobile")} {
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 100px;
    flex-grow: 1;
  }
`

const NewLabel = styled.div`
  height: 24px;
  border-radius: 12px;
  background-color: #4052ab;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 8px;
`

const GalleryImage = styled(RoundedImage)`
  cursor: pointer;
`

class About extends React.Component {
  constructor(props) {
    super(props)
    this.apartmentsGalleryRef = React.createRef()
    this.clinicGalleryRef = React.createRef()
  }

  openClinicGallery = index => this.clinicGalleryRef.current.open(index)

  openApartmentsGallery = index => this.apartmentsGalleryRef.current.open(index)

  render() {
    const PHOTOS_BP = "mobile"

    const {
      location: { pathname },
      intl: { language, t },
      data: {
        topImage,
        aboutAllImage,
        aboutMobileImage1,
        aboutMobileImage2,
        aboutMobileImage3,
        aboutMobileImage4,
        aboutMobileImage5,
        aboutMobileImage6,
        aboutMobileImage7,
        aboutMobileImage8,

        apartmentImage1,
        apartmentImage2,
        apartmentImage3,
        apartmentImage4,
        apartmentImage5,
        apartmentImage6,
        apartmentImage7,
        apartmentImage8,
        apartmentImage9,
        apartmentImage10,
        apartmentImage11,
        apartmentImage12,
        apartmentImage13,
        apartmentImage14,
        apartmentImage15,

        clinicImage1,
        clinicImage2,
        clinicImage3,
        clinicImage4,
        clinicImage5,
        clinicImage6,
        clinicImage7,
        clinicImage8,
        clinicImage9,
        clinicImage10,
        clinicImage11,

        apartmentImageThumb1,
        apartmentImageThumb2,
        apartmentImageThumb3,

        clinicImageThumb1,
        clinicImageThumb2,
        clinicImageThumb3,
        clinicImageThumb4,
      },
    } = this.props

    const { procedureCategories } = extractNodesFromDataApplyLangs(
      this.props.data,
      language
    )
    const orthopedicsCat = procedureCategories.find(
      ({ isOrthopedics }) => isOrthopedics
    )
    const orthopedicsAnchor = orthopedicsCat
      ? createAnchor(orthopedicsCat.name)
      : ""

    const aboutMobileImages = [
      aboutMobileImage1,
      aboutMobileImage2,
      aboutMobileImage3,
      aboutMobileImage4,
      aboutMobileImage5,
      aboutMobileImage6,
      aboutMobileImage7,
      aboutMobileImage8,
    ]
    const apartmentsGalleryImages = [
      {
        image: apartmentImage1,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage2,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage3,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage4,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage5,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage6,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage7,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage8,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage9,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage10,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage11,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage12,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage13,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage14,
        text: t("Interiér Formé clinic"),
      },
      {
        image: apartmentImage15,
        text: t("Interiér Formé clinic"),
      },
    ]
    const clinicGalleryImages = [
      {
        image: clinicImage1,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage2,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage3,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage4,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage5,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage6,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage7,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage8,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage9,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage10,
        text: t("Interiér Formé clinic"),
      },
      {
        image: clinicImage11,
        text: t("Interiér Formé clinic"),
      },
    ]

    const OpenGalleryButton = props => (
      <Col {...props} alignSelf="flex-end" mobileWidth="100%" width="auto">
        <Button.Dashed
          height="auto"
          onClick={() => this.openApartmentsGallery(3)}
          responsiveFullWidth
        >
          <T>Zobrazit všechny fotografie</T>
        </Button.Dashed>
      </Col>
    )

    return (
      <>
        <SEO
          title={t("about - meta title")}
          description={t("about - meta desc")}
          pathname={pathname}
        />
        <Gap.Top />
        <Row responsive bp={topBp}>
          <Col maxWidth="650px">
            <Title.PageTitle>
              <T>Proč si vybrat nás?</T>
            </Title.PageTitle>
            <Gap gap="22px" />
            <Text.Intro>
              <T>
                Jsme výjimeční naším osobním přístupem. Nechodí k nám nechodí
                jen celebrity, jsme klinika pro běžné lidi, s rodinnou
                atmosférou. Máme all inclusive balíčky. Nejsme továrna na nová
                těla, ale specializovaná klinika s pěti lůžky, kde je dost času
                na nadstandard.
              </T>
            </Text.Intro>
          </Col>
          <Gap gap="73px" mobileGap="80px" bp={topBp} />
          {/* <Col grow={1} basis="416px" position="relative"> */}
          <Col grow={1} width="416px" maxWidth="100%" position="relative">
            <TopImageWrapper>
              <GatsbyImage
                image={topImage.image.gatsbyImageData}
                alt="Mudr. Vasek"
              />
            </TopImageWrapper>
          </Col>
        </Row>

        <Gap gap="120px" mobileGap="28px" bp={topBp} />

        <AboutSection
          image={aboutAllImage}
          mobileImages={aboutMobileImages}
          t={t}
          wide={language === "de"}
        />

        <Gap gap="80px" mobileGap="20px" bp={1050} />

        <Col maxWidth="980px" width="100%" alignSelf="center">
          <Col alignItems="flex-start" width="100%">
            <WaveImage />
            <Gap gap="24px" />
            <Title.BiggerTitle>
              <T>Estetická plastika</T>
            </Title.BiggerTitle>
            <Gap gap="40px" mobileGap="16px" />
            <Row alignItems="flex-start">
              <VH showAfter="mobile">
                <Row>
                  <img src={plasticIcon} alt="" />
                  <Gap gap="32px" />
                </Row>
              </VH>
              <Col>
                <Text maxWidth="420px" responsive>
                  <T>
                    Provádíme kompletní škálu estetických plastických operací -
                    zvětšení a modelaci prsou, liposukci, plastiku nosu a další.
                    Ať už vás k nám přivedou vrozené dispozice, věk, nebo úraz,
                    jsme připraveni vám pomoci.
                  </T>
                </Text>
                <Gap gap="26px" />
                <Row>
                  <Gap gap="80px" hideBelow={800} />
                  <Col alignItems="flex-start" maxWidth="553px">
                    <Title.SmallerTitle>
                      <T>Konzultace je zdarma a nezávazná</T>
                    </Title.SmallerTitle>
                    <Gap gap="16px" />
                    <Text responsive maxWidth="420px">
                      <T>
                        Přijďte se podívat. Dáte si kávu, prohlédnete si kliniku
                        a zeptáte se lékaře na všechno, co Vás zajímá. Jsme
                        stále online. Poradíme, i když to k nám máte daleko. S
                        našimi lékaři si můžete povídat i z pohodlí vašeho
                        domova.
                      </T>
                    </Text>
                    <Gap gap="26px" />
                    <Button.Link
                      responsiveFullWidth
                      dashed
                      to={Link.PROCEDURES}
                    >
                      <T>zákroky estetické plastiky</T>
                    </Button.Link>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Gap gap="80px" mobileGap="48px" />

            {/* <WaveImage /> */}
            {/* <Gap gap="24px" /> */}

            {/* <Row>
              <Title.BiggerTitle>
                <T>Ortopedie</T>
              </Title.BiggerTitle>
              <NewLabel>
                <T>Nové</T>
              </NewLabel>
            </Row>
            <Gap gap="40px" mobileGap="16px" />
            <Row alignItems="flex-start">
              <VH showAfter="mobile">
                <Row>
                  <img src={orthopedicsIcon} alt="" />
                  <Gap gap="32px" />
                </Row>
              </VH>
              <Col>
                <Text maxWidth="420px" responsive>
                  <T>
                    Díky špičkovému vybavení moderních operačních sálů a
                    profesionálnímu specialozovanému personálu vám Formé Clinic
                    poskytne tu nejlepší péči v oblasti ortopedie. Zabýváme
                    léčbou poruch pohybového aparátu dle nejnovějších
                    medicínských poznatků.
                  </T>
                </Text>
                <Gap gap="26px" />
                <Button.Link
                  responsiveFullWidth
                  dashed
                  to={Link.PROCEDURES}
                  anchor={orthopedicsAnchor}
                >
                  <T>ortopedické zákroky</T>
                </Button.Link>
              </Col>
            </Row> */}

            {/* <Gap gap="80px" mobileGap="48px" /> */}
          </Col>

          <Col alignItems="flex-start" width="100%">
            <WaveImage />
            <Gap gap="24px" />
            <Title.BiggerTitle>
              <T>Jak to u nás vypadá?</T>
            </Title.BiggerTitle>
            <Gap gap="31px" mobileGap="16px" />

            <GalleryPopup
              ref={this.clinicGalleryRef}
              data={clinicGalleryImages}
            />
            <Row width="100%" responsive bp={PHOTOS_BP}>
              <Col width="553px" mobileWidth="100%" maxWidth="100%" shrink="1">
                <div onClick={() => this.openClinicGallery(0)}>
                  <GalleryImage
                    image={clinicImageThumb1.image.gatsbyImageData}
                    alt="clinic interior"
                  />
                </div>
                <Gap gap="24px" mobileGap="16px" />
                <div onClick={() => this.openClinicGallery(2)}>
                  <GalleryImage
                    image={clinicImageThumb3.image.gatsbyImageData}
                    alt="clinic interior"
                  />
                </div>
              </Col>
              <Gap gap="34px" mobileGap="16px" bp={PHOTOS_BP} />
              <Col width="392px" mobileWidth="100%" maxWidth="100%" shrink="1">
                <Gap gap="46px" desktop bp={PHOTOS_BP} />
                <div onClick={() => this.openClinicGallery(1)}>
                  <GalleryImage
                    image={clinicImageThumb2.image.gatsbyImageData}
                    alt="clinic interior"
                  />
                </div>
                <Gap gap="24px" mobileGap="16px" />
                <Col
                  width="100%"
                  maxWidth="358px"
                  shrink="1"
                  mobileWidth="100%"
                  mobileMaxWidth="none"
                >
                  <div onClick={() => this.openClinicGallery(3)}>
                    <GalleryImage
                      image={clinicImageThumb4.image.gatsbyImageData}
                      alt="clinic interior"
                    />
                  </div>
                </Col>
                <Gap gap="24px" mobileGap="16px" />
                <Col alignSelf="flex-start" mobileWidth="100%" width="auto">
                  <Button.Dashed
                    height="auto"
                    onClick={() => this.openClinicGallery(4)}
                    responsiveFullWidth
                  >
                    <T>Zobrazit všechny fotografie</T>
                  </Button.Dashed>
                </Col>
              </Col>
            </Row>
          </Col>

          <Gap gap="80px" mobileGap="48px" />

          {/* <Col alignItems="flex-start" width="100%">
            <WaveImage />
            <Gap gap="24px" />
            <Title.BiggerTitle>
              <T>Apartmány v budově kliniky</T>
            </Title.BiggerTitle>
            <Gap gap="14px" />
            <Text responsive maxWidth="553px">
              <T>
                Ten nejlepší odpočinek i po zákroku. Na naší klinice máme k
                dispozici moderní apartmány pro klienty z daleka i pro rodinné
                příslušníky. Můžete tak být nablízku našim lékařům i
                zdravotnickému personálu i po propuštění z kliniky.
              </T>
            </Text>

            <GalleryPopup
              ref={this.apartmentsGalleryRef}
              data={apartmentsGalleryImages}
            />
            <Gap gap="32px" mobileGap="24px" />

            <Row width="100%" responsive bp="mobile">
              <Col width="553px" mobileWidth="100%" maxWidth="100%" shrink="1">
                <div onClick={() => this.openApartmentsGallery(0)}>
                  <GalleryImage
                    image={apartmentImageThumb1.image.gatsbyImageData}
                    alt="apartman interior"
                  />
                </div>
                <Gap gap="24px" desktop />
                <OpenGalleryButton desktop />
              </Col>
              <Gap gap="34px" mobileGap="16px" />
              <Col width="358px" mobileWidth="100%" maxWidth="100%" shrink="1">
                <div onClick={() => this.openApartmentsGallery(1)}>
                  <GalleryImage
                    image={apartmentImageThumb2.image.gatsbyImageData}
                    alt="apartman interior"
                  />
                </div>
                <Gap gap="24px" mobileGap="16px" />
                <Col
                  width="100%"
                  maxWidth="295px"
                  shrink="1"
                  mobileWidth="100%"
                  mobileMaxWidth="none"
                >
                  <div onClick={() => this.openApartmentsGallery(2)}>
                    <GalleryImage
                      image={apartmentImageThumb3.image.gatsbyImageData}
                      alt="apartman interior"
                    />
                  </div>
                </Col>
                <Gap gap="16px" mobile />
                <OpenGalleryButton mobile />
              </Col>
            </Row>
          </Col>

          <Gap gap="80px" mobileGap="48px" /> */}

          <Col alignItems="flex-start">
            <WaveImage />
            <Gap gap="24px" />
            <Title.BiggerTitle>
              <T>Co byste měli vědět o zákroku</T>
            </Title.BiggerTitle>
          </Col>

          <Gap gap="31px" />
          <Row justifyContent="space-between" maxWidth="856px" responsive>
            {PROCEDURES_INFOS.slice(0, 3).map(({ text, path }, key) => (
              <Fragment key={key}>
                <ProcedureWrapper key={key}>
                  <IconWrapper>
                    <img src={path} alt="" />
                  </IconWrapper>
                  <Gap gap="16px" mobileGap="24px" />
                  <Text maxWidth="260px">
                    <T>{text}</T>
                  </Text>
                </ProcedureWrapper>
                <Gap gap="24px" />
              </Fragment>
            ))}
          </Row>
        </Col>

        <Gap gap="80px" mobileGap="48px" />

        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(About)

export const pageQuery = graphql`
  fragment TopImage on File {
    image: childImageSharp {
      gatsbyImageData(
        width: 416
        quality: 90
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }

  fragment AboutAllImage on File {
    image: childImageSharp {
      gatsbyImageData(
        width: 563
        quality: 100
        placeholder: NONE
        layout: FIXED
      )
    }
  }

  fragment AboutMobileImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 76, quality: 100, placeholder: NONE, layout: FIXED)
    }
  }

  fragment GalleryThumb on File {
    image: childImageSharp {
      gatsbyImageData(
        width: 553
        quality: 90
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }

  {
    procedureCategories: allProcedurecategories(
      sort: { fields: sortOrder, order: ASC }
    ) {
      edges {
        node {
          ...ProcedureCategoryFragment
        }
      }
    }
    topImage: file(relativePath: { eq: "images/about/top2.png" }) {
      ...TopImage
    }
    aboutAllImage: file(relativePath: { eq: "images/about/about-all.png" }) {
      ...AboutAllImage
    }
    aboutMobileImage1: file(relativePath: { eq: "images/about/about1.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage2: file(relativePath: { eq: "images/about/about2.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage3: file(relativePath: { eq: "images/about/about3.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage4: file(relativePath: { eq: "images/about/about4.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage5: file(relativePath: { eq: "images/about/about5.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage6: file(relativePath: { eq: "images/about/about6.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage7: file(relativePath: { eq: "images/about/about7.png" }) {
      ...AboutMobileImage
    }
    aboutMobileImage8: file(relativePath: { eq: "images/about/about8.png" }) {
      ...AboutMobileImage
    }
    apartmentImageThumb1: file(
      relativePath: { eq: "images/apartments-gallery/1.jpg" }
    ) {
      ...GalleryThumb
    }
    apartmentImageThumb2: file(
      relativePath: { eq: "images/apartments-gallery/2.jpg" }
    ) {
      ...GalleryThumb
    }
    apartmentImageThumb3: file(
      relativePath: { eq: "images/apartments-gallery/3.jpg" }
    ) {
      ...GalleryThumb
    }
    apartmentImage1: file(
      relativePath: { eq: "images/apartments-gallery/1.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage2: file(
      relativePath: { eq: "images/apartments-gallery/2.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage3: file(
      relativePath: { eq: "images/apartments-gallery/3.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage4: file(
      relativePath: { eq: "images/apartments-gallery/4.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage5: file(
      relativePath: { eq: "images/apartments-gallery/5.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage6: file(
      relativePath: { eq: "images/apartments-gallery/6.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage7: file(
      relativePath: { eq: "images/apartments-gallery/7.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage8: file(
      relativePath: { eq: "images/apartments-gallery/8.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage9: file(
      relativePath: { eq: "images/apartments-gallery/9.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage10: file(
      relativePath: { eq: "images/apartments-gallery/10.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage11: file(
      relativePath: { eq: "images/apartments-gallery/11.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage12: file(
      relativePath: { eq: "images/apartments-gallery/12.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage13: file(
      relativePath: { eq: "images/apartments-gallery/13.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage14: file(
      relativePath: { eq: "images/apartments-gallery/14.jpg" }
    ) {
      ...GalleryImageFragment
    }
    apartmentImage15: file(
      relativePath: { eq: "images/apartments-gallery/15.jpg" }
    ) {
      ...GalleryImageFragment
    }
    clinicImageThumb1: file(
      relativePath: { eq: "images/clinic-gallery/1.jpg" }
    ) {
      ...GalleryThumb
    }
    clinicImageThumb2: file(
      relativePath: { eq: "images/clinic-gallery/2.jpg" }
    ) {
      ...GalleryThumb
    }
    clinicImageThumb3: file(
      relativePath: { eq: "images/clinic-gallery/3.jpg" }
    ) {
      ...GalleryThumb
    }
    clinicImageThumb4: file(
      relativePath: { eq: "images/clinic-gallery/4.jpg" }
    ) {
      ...GalleryThumb
    }
    clinicImage1: file(relativePath: { eq: "images/clinic-gallery/1.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage2: file(relativePath: { eq: "images/clinic-gallery/2.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage3: file(relativePath: { eq: "images/clinic-gallery/3.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage4: file(relativePath: { eq: "images/clinic-gallery/4.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage5: file(relativePath: { eq: "images/clinic-gallery/5.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage6: file(relativePath: { eq: "images/clinic-gallery/6.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage7: file(relativePath: { eq: "images/clinic-gallery/7.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage8: file(relativePath: { eq: "images/clinic-gallery/8.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage9: file(relativePath: { eq: "images/clinic-gallery/9.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage10: file(relativePath: { eq: "images/clinic-gallery/10.jpg" }) {
      ...GalleryImageFragment
    }
    clinicImage11: file(relativePath: { eq: "images/clinic-gallery/11.jpg" }) {
      ...GalleryImageFragment
    }
  }
`
