// external imports
import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData, extractNodesFromDataApplyLangs } from '../lib/helpers'
import { applyLang, applyLangSingle } from '../lib/intl'
import injectIntl from '../lib/intl/injectIntl'

// components
import Layout from '../layouts/DefaultLayout'
import SEO from '../components/SEO'
import T from '../lib/intl/T'
import IntlContext from '../lib/intl/IntlContext'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  Button,
  OurDoctorsSection,
  TestimonialsSection,
  WhyUsSection,
  HpTop,
  OurWorkSection,
  WaveImage,
  ContactFormSection,
  OrthopedicsSection,
  EpilationSection,
  VH,
} from '../components'
import ChangesSuggestions from '../components/ChangesSuggestions'
import PostSuggestions from '../components/PostSuggestions'

import priceIconPath from '../data/images/price-icon.svg'
// assets
// import heroImage from '../data/images/logo.png'

// custom styled components
// const ResTest = styled.div`
//   ${addResponsivity()}
//   background: yellow;
//   ${up('mobile')} {
//     background: red;
//   }
// `

class Index extends React.Component {
  render() {
    const {
      intl: { language, t },
      data: {
        ourDoctorsImage,
        orthopedicsImage,
        epilationImage,
        plasticSurgeryImage,
        firstDoctorImage,
        secondDoctorImage,
        thirdDoctorImage,
        whyUsImage,
        firstWhyUsImage,
        secondWhyUsImage,
        thirdWhyUsImage,
        hpTopImage,
      } = {},
    } = this.props

    const { posts, changes, testimonials, settings, procedureCategories } =
      extractNodesFromDataApplyLangs(this.props.data, language)

    return (
      <>
        <SEO
          // index has no title template only "FORMÉ clinic"
          title={t('Plastická a estetická chirurgie v Praze · FORMÉ clinic')}
          titleTemplate={t('Plastická a estetická chirurgie v Praze · FORMÉ clinic')}
        />
        <HpTop image={hpTopImage} language={language} />

        <Gap gap="78px" />

        <WhyUsSection
          image={whyUsImage}
          mobileImages={[firstWhyUsImage, secondWhyUsImage, thirdWhyUsImage]}
        />

        <Col alignItems="flex-start" width="100%" maxWidth="1010px" margin="auto">
          <Gap gap="120px" mobileGap="48px" />

          <OurDoctorsSection
            image={ourDoctorsImage}
            doctorsImages={[firstDoctorImage, secondDoctorImage, thirdDoctorImage]}
          />

          <Gap gap="78px" mobileGap="78px" />

          <Row responsive bp="menu" width="100%">
            <VH showAfter="menu">
              <Row>
                <GatsbyImage image={plasticSurgeryImage.image.gatsbyImageData} />
                <Gap gap="40px" />
              </Row>
            </VH>
            <Col maxWidth="489px">
              <Col width="100%" alignItems="flex-start">
                <Gap gap="40px" mobileGap="0" bp="menu" />
                <WaveImage />
                <Gap gap="24px" />
                <Title.BigTitle>
                  <T>Plastická a estetická medicína</T>
                </Title.BigTitle>
              </Col>
              <Col width="100%" alignItems="flex-start">
                <Gap gap="24px" mobileGap="16px" />
                <Text responsive>
                  <T>
                    Provádíme kompletní škálu estetických plastických operací. Ať už vás k nám
                    přivedou vrozené dispozice, touha po krásnějším vzhledu nebo úraz, jsme
                    připraveni vám pomoci.
                  </T>
                </Text>
                <VH showBelow="menu">
                  <Gap gap="26px" />
                  <GatsbyImage image={plasticSurgeryImage.imageMobile.gatsbyImageData} />
                </VH>
                <Gap gap="26px" />
                <Row maxWidth="318px" width="100%">
                  <Button.Link to={Link.PROCEDURES} responsiveFullWidth bp={840} dashed>
                    <T>Zákroky</T>
                  </Button.Link>
                </Row>
              </Col>
            </Col>
          </Row>
          <Gap gap="40px" mobileGap="0" bp="menu" />
          <Gap gap="80px" mobileGap="48px" />

          {/* <OrthopedicsSection image={orthopedicsImage} procedureCategories={procedureCategories} /> */}
          <EpilationSection image={epilationImage} />
          <Gap gap="83px" mobileGap="48px" />
          {/* <OurDoctorsSection
          image={ourDoctorsImage}
          doctorsImages={[firstDoctorImage, secondDoctorImage, thirdDoctorImage]}
        />
        <Gap gap="78px" /> */}

          <OurWorkSection settings={settings} />
        </Col>

        <ContactFormSection isFooter={false} isHp ctaName="homepage" />

        <Gap gap="80px" mobileGap="50px" />
        <ChangesSuggestions changes={changes} />
        <Col alignItems="center" mobileAlignItems="stretch">
          <Button.Link responsiveFullWidth dashed to={Link.CHANGES}>
            <T>další fotky před a po</T>
          </Button.Link>
        </Col>

        <Gap gap="80px" mobileGap="40px" />

        <Col alignItems="flex-start" width="100%" maxWidth="1010px" margin="auto">
          <WaveImage />
          <Gap gap="34px" />
          <Row responsive alignItems="flex-start">
            <img alt="price icon" src={priceIconPath} alt="" style={{ paddingTop: '5px' }} />
            <Gap gap="16px" mobileGap="26px" />
            <Title.MediumTitle>
              <T>Ceny najdete v detailu jednotlivého zákroku.</T>
            </Title.MediumTitle>
          </Row>
        </Col>

        <Gap gap="100px" mobileGap="50px" />

        <TestimonialsSection data={testimonials} />
        <Gap gap="80px" mobileGap="50px" />
        <PostSuggestions posts={posts} title="Blog" />
        <Col alignItems="center" mobileAlignItems="stretch">
          <Button.Link responsiveFullWidth dashed to={Link.BLOG}>
            <T>další články</T>
          </Button.Link>
        </Col>
        <Gap gap="80px" mobileGap="50px" />
      </>
    )
  }
}

export default injectIntl(Index)

export const pageQuery = graphql`
  fragment OurDoctorsImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 550, quality: 90, placeholder: NONE, layout: FIXED)
    }
  }

  fragment DoctorMobileImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 160, quality: 90, placeholder: NONE, layout: FIXED)
    }
  }

  fragment WhyUsImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 444, quality: 90, placeholder: NONE, layout: FIXED)
    }
  }

  fragment WhyUsMobileImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 160, quality: 90, placeholder: NONE, layout: FIXED)
    }
  }

  fragment HpTopImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 648, quality: 100, placeholder: NONE, layout: CONSTRAINED)
    }
  }

  # fragment OrthopedicsImage on File {
  #   image: childImageSharp {
  #     gatsbyImageData(width: 381, quality: 90, placeholder: NONE, layout: FIXED)
  #   }
  #   imageMobile: childImageSharp {
  #     fixed(width: 280, quality: 90) {
  #       ...GatsbyImageSharpFixed_withWebp_noBase64
  #     }
  #   }
  # }

  fragment EpilationImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 381, quality: 90, placeholder: NONE, layout: FIXED)
    }
    imageMobile: childImageSharp {
      gatsbyImageData(width: 280, quality: 90, placeholder: NONE, layout: FIXED)
    }
  }

  fragment PlasticSurgeryImage on File {
    image: childImageSharp {
      gatsbyImageData(width: 400, quality: 90, placeholder: NONE, layout: FIXED)
    }
    imageMobile: childImageSharp {
      gatsbyImageData(layout: FIXED, width: 280, quality: 90)
    }
  }

  fragment OurWorkSettingFragment on Settings {
    _id
    modelName
    operationsCount
    breastCount
    happyClientsCount
    packagesCount
  }

  query ($language: String!) {
    settings {
      ...OurWorkSettingFragment
    }
    procedureCategories: allProcedurecategories(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          ...ProcedureCategoryFragment
        }
      }
    }
    posts: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        lang: { eq: $language }
        isOnHomepage: { eq: true }
        isChange: { eq: false }
      }
      limit: 3
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    changes: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        lang: { eq: $language }
        isChange: { eq: true }
        isOnHomepage: { eq: true }
      }
      limit: 2
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    testimonials: allTestimonials(
      filter: { isOnHomepage: { eq: true } }
      sort: { fields: sortOrder, order: ASC }
    ) {
      edges {
        node {
          ...TestimonialFragment
        }
      }
    }
    ourDoctorsImage: file(relativePath: { eq: "images/doctors/team2.png" }) {
      ...OurDoctorsImage
    }
    firstDoctorImage: file(relativePath: { eq: "images/doctors/jiri_bayer.png" }) {
      ...DoctorMobileImage
    }
    secondDoctorImage: file(relativePath: { eq: "images/doctors/radek_lhotsky.png" }) {
      ...DoctorMobileImage
    }
    thirdDoctorImage: file(relativePath: { eq: "images/doctors/matus_beran.png" }) {
      ...DoctorMobileImage
    }
    # orthopedicsImage: file(
    #   relativePath: { eq: "images/orthopedics/orthopedics-hp.png" }
    # ) {
    #   ...OrthopedicsImage
    # }
    epilationImage: file(relativePath: { eq: "images/epilation/epilation-hp.png" }) {
      ...EpilationImage
    }
    plasticSurgeryImage: file(relativePath: { eq: "images/plastic-surgery-hp.png" }) {
      ...PlasticSurgeryImage
    }
    whyUsImage: file(relativePath: { eq: "images/why-us/all.png" }) {
      ...WhyUsImage
    }
    firstWhyUsImage: file(relativePath: { eq: "images/why-us/first.png" }) {
      ...WhyUsMobileImage
    }
    secondWhyUsImage: file(relativePath: { eq: "images/why-us/second.png" }) {
      ...WhyUsMobileImage
    }
    thirdWhyUsImage: file(relativePath: { eq: "images/why-us/third.png" }) {
      ...WhyUsMobileImage
    }
    hpTopImage: file(relativePath: { eq: "images/hp-top.png" }) {
      ...HpTopImage
    }
  }
`
